import React from "react";
import {format} from "date-fns";
import {ru} from 'date-fns/locale'
import Ribbon from "../../../components/Ribbon";

interface Props {
    name: any // React.PropTypes.instanceOf(Date).isRequired,
    eventType: any
    start: Date | any
    //part: any
}

class EventTitle extends React.Component<Props> {

    renderTime() {
        const {start} = this.props;
        return start ? (
            <time itemProp="startDate">
                {format(new Date(start), 'dd MMMM в HH:mm', {locale: ru})}
            </time>
        ) : 'Не запланировано';
    }

    render() {
        return (
            <div className="event-title-container">
                <Ribbon className="contacts-ribbon" title={this.props?.name}/>
                {/*      <Ribbon title={this.props.name}/>*/}
                <div className="event-title-date">
                    <span>{this.props?.eventType?.name}</span> · <span>{this.renderTime()}</span>
                    {/*    {this.props.type}*/}
                    {/*  {this.props.type} · {this.renderTime()}*/}
                </div>
            </div>
        );
    }
}

export default EventTitle;
